.max-candidate-select {
  &__button {
    white-space: nowrap;
  }

  .modal-content {
    border-radius: 25px;
    max-width: 535px;

  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1px;
    width: 100%;

    padding: 38px 52px;
  }

  &__content_title {
    color: var(--darkest);
    padding-bottom: 8px;
  }

  &__content_description {
    color: var(--darkest);
    font-weight: 400;

    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin: 5px 0px;
  }

  &__button-base {
    border-radius: 9px;
    background-color: var(--lightest);
    color: var(--placeholder);
    padding: 18px 10px;
    cursor: pointer;
    border: none;
    font-family: inherit;

    &.selected {
      background-color: var(--strong-darker);
      color: white;
    }
  }

  &__input_base {
    border-radius: 9px;
    background-color: var(--lightest);
    padding: 10px 10px;
    color: var(--strong-darker);

    border: 9px solid;
    width: 60px;
    align-self: stretch;
    text-align: center;

    font-size: 17px;
    font-weight: 700;
    line-height: 23.04px;

    font-family: inherit;


    // Hide the number input arrows
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__content_warning {
    color: var(--strong-darker);
    margin-top: 20px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 14px;

    margin-top: 20px;
  }
}