.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__item {
    cursor: pointer;
    border-radius: 9px;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    line-height: 120%;

    padding: 10px;
    border: none;
    outline: none;

    transition: all 0.2s;

    &--primary {
      color: var(--darkest, #002060);

      &:hover,
      &.selected {
        background: var(--darkest, #002060);
        color: var(--white);
      }
    }

    &--secondary {
      color: var(--white);
      background: transparent;

      transition: all 0.2s;

      &:hover,
      &.selected {
        background: #5ec58f;
      }
    }
  }
}
