.form {
  padding: 30px;
  padding-top: 50px;
  background: #ededed;

  &__title {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 28px;
    font-weight: 700;
    line-height: 135%;

    margin-bottom: 40px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}
