
#choose-pricing-section {
  background-color: var(--lightest, #ededed);
  width: 100%;

  display: flex;
  justify-content: center;

  #choose-pricing-container {
    background-color: var(--lightest-2, #f4f4f4);
    border-radius: 12px;

    z-index: 10;
    position: relative;
    // top: -200px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 60px 5% 38px 5%;
    width: 1052px;

    #choose-pricing-container-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-bottom: 16px;

      input {
        display: flex;
        width: 79px;
        padding: 9px 0px 6px 0px;
        margin: 0 10px;
        justify-content: center;
        align-items: center;

        border-radius: 8px;
        border: 3px solid var(--alt, #18A8D5);
        background: var(--white, #FFF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

        color: var(--alt, #18A8D5);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 135%; /* 37.8px */
      }

      @media (max-width: 768px) {
        p {
          font-size: 12px;
        }

        .h3 {
          font-size: 15px;
        }
      }

      input::placeholder {
        font-weight: 200;
        color: rgba(0, 0, 0, 0.20);
      }

      p:last-child {
        margin: 16px 0 16px 10px;
      }
    }

    #plan-description {
      margin: 42px 0 34px 0;
      padding: 50px 80px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: 20px;

      background: #FFF;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);

      @media (max-width: 768px) {
        padding: 40px 30px;
      }

      button {
        font-size: 17px;
        text-align: left;
        background: var(--strong-darker, #3FA06E);

        display: flex;
        border-radius: 0;
        justify-content: space-between;
        
        width: 100%;
      }
    }
  }
}