.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s 0.3s;

  &.open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out, visibility 0s;
  }

  &-content {
    background-color: white;

    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 520px;
    width: 100%;
    max-height: 80vh;
    margin: 20px;

    overflow-y: auto;

    @media (max-width: 520px) {
      width: calc(100% - 40px);
      max-height: calc(100% - 40px);
      max-width: none;
    }
  }

  .close {
    float: right;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 10px;
    font-size: 20px;
  }

  .close:hover {
    color: red;
  }
}