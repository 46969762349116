.skills-card {
  gap: 1px;
  overflow: hidden;
  border-radius: 0.3rem;
  margin-bottom: 2rem;
}
.skills-card h3 {
  padding-bottom: 1rem;
  color: var(--strong);
}
