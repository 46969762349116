.subscription-button {
  background: var(--darkest, #002060);
  color: var(--white);
  border: none;
  outline: none;
  padding: 15px 16px;

  text-align: right;
  font-family: IBM Plex Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  overflow: hidden;

  &--popular {
    background: var(--strong-darker, #3fa06e);
  }

  &--lowcost {
    background: var(--fribl-blue, #1e4ca9);
  }

  &--ideal {
    background: var(--strong, #5ec58f);
  }

  &--loading {
    pointer-events: none;
    background: #e1e1e1 !important;
    color: var(--white);

    .subscription-button__svg-wrapper {
      pointer-events: none;
      background: #e1e1e1;
      color: var(---white);
    }
  }

  &--secondary {
    padding: 0px;
    border: 1px solid var(--darkest, #002060);
    gap: 0px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  &__text {
    flex: 1;

    &--secondary {
      color: var(--placeholder, #9a8787);
      text-align: right;
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
      box-sizing: border-box;

      background: var(--lightest, #ededed);

      outline: none;
      border: none;

      flex: 1;

      // Placeholder color
      &::placeholder {
        color: var(--placeholder, #9a8787);
      }
    }
  }

  &__svg-wrapper {
    width: 66px;

    padding: 15px 16px;

    background: var(--darkest);
    color: var(---white);

    display: flex;
    align-items: center;
  }
}
