.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;

  .tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    z-index: 1;
    display: inline-block;
    opacity: 0.9;

    top: calc(100% + 3px);
    left: 50%;
    transform: translateX(-50%);

    &__arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px;
      border-color: transparent transparent #333 transparent;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &__content {
      font-size: 14px;
      line-height: 1.5;
      min-width: 100px;
      text-align: center;
    }
  }
}
