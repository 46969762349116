.navbar {
  background: #ededed;
  padding: 30px;

  &__wrapper {
    max-width: 753px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__menus {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0px;

    a {
      padding: 17px 22px;
      color: var(--darkest, #002060);
      font-size: 17px;
      font-weight: 700;
      line-height: 135.5%;
      text-decoration: none;

      &.selected {
        border-radius: 45px;
        background: var(--strong, #5ec58f);
        color: #fff;
      }
    }
  }
}
