/* skill */
.skill {
  background-color: white;
  border-bottom: 1px solid var(--lightest);
  padding: 1rem 1rem 0.2rem 1rem;
}
.score {
  background-color: var(--strong);
  /* background-color: var(--scoreColour); */
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  flex-shrink: 0;
}
.skill-wrap {
  flex-grow: 1;
  padding: 1rem;
  gap: 0.5rem;
}
.tag {
  background-color: var(--lightest);
  color: var(--strong);
  padding: 0.2em 0.5rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: 4px;
  margin-left: 1em;
}
.skill .btn {
  padding: 0.6rem 1.2rem;
  color: white;
}
.skill:hover .btn {
  color: var(--darkest);
}
.skill p {
  height: 0rem;
  overflow: hidden;
  transition: all 0.2s;
}
.skill:hover p {
  height: 4rem;
  min-height: max-content;
  margin-top: 14px;
  margin-bottom: 14px;
}
.dates {
  color: var(--light);
}
