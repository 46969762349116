// Dropdown.scss
.dropdown {
  position: relative;

  &__trigger {
    cursor: pointer;
  }

  &__button {
    color: var(--alt, #18a8d5);
    font-family: IBM Plex Sans;
    font-size: 17px;
    font-weight: 700;
    line-height: 135.5%;
    border: none;
    background: none;
    padding: 14px 20px;
    display: flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;
  }

  &__content {
    position: absolute;
    z-index: 3;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    padding: 24px 16px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s ease-in-out;

    width: 200px;

    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &--open {
    .dropdown__content {
      display: block;
    }
  }
}
