.save-time {
  background: var(--fribl-blue);

  overflow: hidden;

  &__wrapper {
    position: relative;
    max-width: 907px;
    margin: 0 auto;
  }

  &__content {
    padding: 56px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 1;

    &:first-child {
      padding-top: 90px;
    }

    &:nth-child(2) {
      padding-bottom: 150px;
    }
  }

  &__text {
    padding: 29px 33px;
    color: var(--fribl-blue, #1e4ca9);
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 141.5%;
    border-radius: 12px;
    background: #fff;

    margin-bottom: 30px;

    span {
      font-weight: 700;
    }
  }

  &__info {
    display: flex;
    gap: 25px;
    align-self: flex-start;
  }

  &__image {
    border-radius: 1000px;
    width: 60px;
    height: 60px;
    overflow: hidden;
  }

  &__contact {
    display: flex;
    flex-direction: column;

    p {
      color: var(--white, #fff);
      font-family: IBM Plex Sans;
      font-size: 13px;
      line-height: 120%;
    }

    p:first-child {
      font-weight: 700;
    }

    svg {
      margin-top: 13px;
    }
  }

  &__art {
    position: absolute;
    left: -200px;
    top: 33%;
    width: 437px;
    mix-blend-mode: screen;

    @media (max-width: 768px) {
      left: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__title {
    color: white;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 9px;
  }

  &__subtitle {
    font-size: 21px;
    font-weight: 700;
    line-height: 1.5;
    color: var(--strong);
    text-align: center;
  }
}
