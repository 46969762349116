.terms {
  background: #ededed;
  padding: 210px 0px;

  &__wrapper {
    max-width: 660px;
    padding: 0 30px;
    margin: 0 auto;
  }

  &__bold {
    font-weight: 700;
  }

  &__title {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 28px;
    font-weight: 700;
    line-height: 135%;

    margin-bottom: 60px;
  }

  &__body {
    color: var(--placeholder, #9a8787);
    font-size: 16px;
    font-weight: 400;
    line-height: 143%;
    margin-bottom: 20px;

    span {
      font-weight: 700;
    }

    a {
      color: var(--fribl-blue, #1e4ca9);
      text-decoration: underline;
    }
  }

  &__h-level-1 {
    color: var(--fribl-blue, #1e4ca9);

    font-size: 24px;
    font-weight: 800;
    line-height: 143%;
  }

  &__h-level-2 {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 20px;
    font-weight: 700;
    line-height: 143%;
  }

  &__h-level-3 {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 20px;
    font-weight: 700;
    line-height: 143%;
  }

  &__list {
    margin-bottom: 20px;
    list-style: none;

    &--bullet {
      padding-left: 20px;
      list-style: disc;
    }

    &--serial {
      padding-left: 20px;
      list-style: decimal;
    }
  }

  &__list-item {
    color: var(--placeholder, #9a8787);
    font-size: 16px;
    font-weight: 400;
    line-height: 143%;
  }

  &__table-wrapper {
    overflow-x: auto;
    max-width: 100%;

    border: 1px solid var(--placeholder, #9a8787);
    margin-bottom: 20px;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      padding: 10px 20px;
      border: 1px solid var(--placeholder, #9a8787);
    }
  }
}
