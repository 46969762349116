.SignUpPage {
  min-height: 100vh;
  background: var(--darkest);

  display: flex;
  align-items: center;

  padding: 20px;

  &__Content {
    max-width: 768px;
    width: 100%;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 40px;
  }

  &__Footer {
    font-size: 17px;
    color: #333;
    text-align: center;

    font-weight: 500;
  }

  &__Link {
    text-decoration: none;
    color: #39f;
    text-decoration: underline;
  }

  &__Subtitle {
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
