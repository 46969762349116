.features {
  background: var(--lightest, #ededed);

  &__wrapper {
    max-width: 1065px;
    padding: 30px;
    margin: 0 auto;

    position: relative;
    top: -200px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding: 20px 40px;
    background: #f4f4f4;
  }

  &__info-title {
    text-align: center;
    color: var(--darkest, #002060);
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
  }

  &__info-info {
    text-align: center;
    color: var(--strong-darker, #3fa06e);
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
  }

  &__title {
    color: var(--darkest, #002060);
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 135%;

    margin-top: 71px;
    margin-bottom: 58px;
  }

  &__cards {
    display: grid;
    // Auto fit with min width of 317px
    grid-template-columns: repeat(auto-fit, minmax(317px, 1fr));
    gap: 27px;
  }

  &__card {
    padding: 48px 31px;
    background: rgba(255, 255, 255, 0.4);

    display: flex;
    flex-direction: column;
    gap: 16px;

    &--secondary {
      justify-content: flex-end;
    }
  }

  &__card-title {
    color: var(--strong-darker, #3fa06e);
    font-size: 21px;
    font-weight: 700;
    line-height: 149.5%;

    &--secondary {
      color: var(--darkest, #002060);
    }
  }

  &__card-text {
    color: var(--darkest, #002060);
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;

    &--bold {
      font-weight: 700;
    }

    &--secondary {
      color: var(--strong-darker, #3fa06e);
      font-weight: 700;
    }
  }
}