.import-cv {
  background: #ededed;
  min-height: 100vh;
  padding: 110px 30px;

  @media (max-width: 768px) {
    padding: 64px 0px;
  }

  &__wrapper {
    max-width: 509px;
    margin: 0 auto;
    border-radius: 25px;
    background: var(--faint-white, rgba(255, 255, 255, 0.4));

    padding: 38px 49px;
    margin-bottom: 27px;

    @media (max-width: 768px) {
      padding: 30px;
    }
  }

  &__title {
    font-weight: 700;
    color: var(--fribl-blue);

    margin-bottom: 30px;
  }

  &__candidates {
    margin: 20px 0px;

    display: flex;
    flex-direction: column;
  }

  &__candidates-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    margin-top: 12px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }

  // Components
  .candidate {
    background: var(--white, #fff);
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 3px;
  }

  .select-all {
    padding: 2px 16px;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
