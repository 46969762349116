$spacer: 0.25rem;
$size-range: 101;

@for $i from 0 through $size-range {
  .p-#{$i} {
    padding: $i * $spacer !important;
  }
  .pt-#{$i} {
    padding-top: $i * $spacer !important;
  }
  .pr-#{$i} {
    padding-right: $i * $spacer !important;
  }
  .pb-#{$i} {
    padding-bottom: $i * $spacer !important;
  }
  .pl-#{$i} {
    padding-left: $i * $spacer !important;
  }
  .px-#{$i} {
    padding-left: $i * $spacer;
    padding-right: $i * $spacer !important;
  }
  .py-#{$i} {
    padding-top: $i * $spacer;
    padding-bottom: $i * $spacer !important;
  }

  .m-#{$i} {
    margin: $i * $spacer !important;
  }
  .mt-#{$i} {
    margin-top: $i * $spacer !important;
  }
  .mr-#{$i} {
    margin-right: $i * $spacer !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * $spacer !important;
  }
  .ml-#{$i} {
    margin-left: $i * $spacer !important;
  }
  .mx-#{$i} {
    margin-left: $i * $spacer;
    margin-right: $i * $spacer !important;
  }
  .my-#{$i} {
    margin-top: $i * $spacer;
    margin-bottom: $i * $spacer !important;
  }

  .text-#{$i} {
    font-size: $i * $spacer !important;
  }
}

.ml-auto {
  margin-left: auto;
}

$colors: (
  "darkest": #002060,
  "strong": #5ec58f,
  "strong-darker": #3fa06e,
  "lightest": #ededed,
  "fribl-blue": #1e4ca9,
  "alt": #18a8d5,
  "alt-darker": #118db3,
  "white": #fff,
  "error": red,
  "warning-orange": #f63,
  "placeholder": #9a8787,
);

@each $index, $color in $colors {
  .text-#{$index} {
    color: $color;
  }
  .bg-#{$index} {
    background-color: $color;
  }
  .border-#{$index} {
    border-color: $color;
  }
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.rounded-full {
  border-radius: 50%;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.h1 {
  font-size: 44px;
  font-weight: 600;
  line-height: 110%;

  @media (max-width: 1024px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }
}

.h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 136.5%;

  @media (max-width: 1024px) {
    font-size: 26px;
  }

  @media (max-width: 768px) {
    font-size: 21px;
  }
}

.h4 {
  font-size: 21px;
  font-weight: 700;
  line-height: 135%; /* 28.35px */
}

.p {
  font-size: 17px;
  line-height: 135%;

  &--bold {
    font-weight: 700;
  }
}

.text-center {
  text-align: center;
}

.tiny {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
