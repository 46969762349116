/* components/Page2.css */
.requirements-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: var(--lightest);
  min-height: 700px;
  width: 100%;
  padding: 3rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  &__content {
    max-width: 700px;
    margin: 0 auto;
  }

  &__header {
    margin-bottom: 20px;
  }

  &__title {
    color: var(--darkest, #002060);
    margin-bottom: 10px;

    span {
      color: var(--strong, #5ec58f);
    }
  }

  &__topbar {
    padding: 2rem;
    gap: 0.5rem;
    background-color: var(--darkest);
    color: white;
    max-width: 700px;
    width: 100%;

    border-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    @media (max-width: 520px) {
      flex-direction: column;
      padding: 1rem;
    }

    p {
      font-size: 17px;
      font-weight: 700;
      line-height: 135.5%;
    }
  }


  &__info {
    background: var(--alt);
    color: var(--white);

    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    border-radius: 9px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    padding: 22px 56px;
    margin-top: 2px;
    text-align: left;

    a {
      color: var(--white);
    }

  }

  // Components
  .box {
    border-radius: 0.5rem;
    max-width: 700px;
    width: 100%;

    margin: 30px 0px;

    h3,
    h4 {
      text-transform: capitalize;
      color: var(--strong);
      margin-bottom: 2px;
    }

    h4 {
      color: var(--darkest);
      margin-bottom: 17px;
    }

    &__requirements {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 7px;

    }
  }

  .req-add {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 57px;

    padding: 0px 10px;
    margin-bottom: 2px;
    background: rgba(255, 255, 255, .5);

    border-radius: 7px;

    input {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 135.5%;
      color: #9A8787;
      background: none;
      border: none;
      width: 40px;
      outline: none;

      transition: width 0.3s;

      &:focus {
        width: 300px;
      }

      // If has text set width to 300
      &:not(:placeholder-shown) {
        width: 300px;
      }
    }

    &__icon {
      background: rgba(94, 197, 143, 0.2);
      border-radius: 4px;
      padding: 10px;
    }
  }

  // 
  .requirement {
    flex-shrink: 0;
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    position: relative;

    min-height: 57px;
    max-width: 100%;

    padding: 13px 10px;
    margin-bottom: 2px;
    background: var(--white);
    border-radius: 7px;

    &__add-must-have {
      flex-shrink: 0;
      border: none;
      background: none;
      cursor: pointer;
      overflow: hidden;

      font-family: 'IBM Plex Sans';
      font-weight: 700;
      font-size: 11px;
      line-height: 120%;
      letter-spacing: 0.12em;
      color: var(--alt);

      text-align: center;

      span {
        display: none;
      }

      &:hover {
        padding: 9px 8px;
        border: 1px dashed #18A8D5;
        border-radius: 4px;

        span {
          display: inline-block;
        }

        svg {
          display: none;
        }
      }
    }

    &__remove-must-have {
      flex-shrink: 0;
      border: none;
      background: none;
      cursor: pointer;

      font-family: 'IBM Plex Sans';
      font-weight: 700;
      font-size: 11px;
      line-height: 120%;
      letter-spacing: 0.12em;

      span {
        display: none;
      }

      // Don't break the line
      white-space: nowrap;
      background: #18A8D526;
      padding: 9px 8px;

      color: var(--alt);


      &:hover {
        background: var(--lightest);
        color: var(--placeholder);
        text-decoration: line-through;
        border: none;



      }

    }

    &__experience {
      flex-shrink: 0;
      align-self: center;
      white-space: nowrap;

      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      height: 32px;
      padding: 9px 13px;
      overflow: hidden;

      background: #5EC58F36;

      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 120%;
      /* or 13px */
      letter-spacing: 0.12em;
      text-transform: uppercase;

      color: var(--strong-darker)
    }

    &__experience-input {
      width: 30px;
      border: none;
      background: none;
      outline: none;

      font-family: inherit;
      color: inherit;
      font-weight: inherit;

      -moz-appearance: textfield;
      border-bottom: 1px dotted var(--strong-darker);

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

    }

    &__edit-btn {
      display: none;
    }

    &__texts {
      display: flex;
      align-items: center;
      gap: 10px;


      &:hover {
        button {
          display: inline-block;
        }

        // Sibling button
        &+.requirement__close-btn {
          display: none;
        }

        .requirement__experience {
          position: relative;
          display: flex;

          &-input {
            display: flex;
          }

          &-value {
            display: none;
          }
        }

        p {
          text-decoration-line: underline;
          text-decoration-style: dotted;
          text-decoration-color: var(--alt-darker);
        }
      }
    }

    &__text-input {
      font-family: inherit;
      resize: none;
      width: 100%;
      font-size: 16px;
      padding: 8px;
    }


    &__text {
      display: inline-block;
      width: 100%;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.35;
      padding: 4px 0px;


      &--new {
        margin-bottom: 6px;
        font-weight: 700;
      }

      &--old {
        font-size: 13px;
      }
    }

    &__edit-btn {
      width: 30px;
    }

    &__close-btn {
      width: 30px;
    }
  }

  .weights {
    padding: 50px 88px 32px;
    background: var(--darkest);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;

    &__title {
      font-size: 13px;
      line-height: 120%;
      color: var(--white);
      align-self: start;
    }

    &__sliders {
      align-self: stretch;
      margin: 12px 0px;
      margin-bottom: 24px;


      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 28px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 10px;

      .btn:nth-child(1) {
        padding: 17px 38px;
      }

      .btn:nth-child(2) {
        padding: 17px 21px;
      }
    }

  }

  .weight {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 220px);
    gap: 20px;

    &__label {
      font-weight: 700;
      color: var(--alt);
    }

    &__labels {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__slider {}
  }
}

.requirement {
  &__highlights {
    &-wrapper {
      margin: 30px 0px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 1px;
      overflow: hidden;
    }

    &-item {
      background-color: #5ec58f;
      padding: 18px 25px;
      color: white;
      display: flex;
    }

    &-title {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
    }

    &-tag {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 11px;
      line-height: 120%;
      letter-spacing: 0.15em;
      color: #5ec58f;

      background-color: white;
      border-radius: 4px;
      padding: 10px 20px;
    }

    &-info {
      font-size: 17px;
      line-height: 135.5%;
      font-weight: 400;

      span {
        font-weight: 700;
      }
    }

  }
}


.requirements-page {
  .rc-slider-handle {
    width: 14px;
    background: #D9D9D9;
    opacity: 1;
    border: none;
  }

  .rc-slider-handle-dragging {
    background: var(--alt);
    border: none !important;
    box-shadow: none !important;
  }

  .rc-slider-step {
    background: #D1CFCF;
  }

  .rc-slider-track {
    background: #D1CFCF;
  }
}