.delete-dialog {
  &__container {
    padding: 30px;
  }

  h2 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 12px;
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }
}