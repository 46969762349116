.ResumePage {
  max-width: 100%;
  padding: 3rem;

  @media (max-width: 1024px) {
    grid-template-columns: minmax(0, 1fr);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  .long-text {
    line-break: anywhere;
  }

  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1.3fr);
    grid-gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__nav {

    margin-bottom: 35px;

    display: flex;
    align-items: center;
  }

  &__back-btn {
    color: var(--alt-darker, #118db3);
    font-size: 17px;
    font-weight: 700;
    line-height: 120%;
    text-decoration: none;

    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  &__nav-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    flex: 1;
  }

  &__nav-btn {
    border-radius: 30px;
    padding: 3px 12px;
    cursor: pointer;

    font-family: inherit;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    border: none;
    outline: none;
    background: none;

    color: var(--alt-darker);

    &.selected {
      background: var(--alt-darker, #118DB3);
      color: var(--white);
    }
  }

  &__checkbox {
    color: #fff;
    font-family: "IBM Plex Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    padding: 15px 22px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 19px;

    border: none;
    border-radius: 1000px;
    background: var(--alt, #18a8d5);

    max-width: 280px;

    cursor: pointer;
  }

  &__no-data {
    font-size: 18px;
    padding: 12px 0px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: var(--darkest);
  }

  &__go-back {
    font-size: 18px;
    padding: 12px 0px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: var(--fribl-blue);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.view {
  flex-grow: 1;
  background-color: var(--lightest);
  padding: 7rem;
  gap: 2rem;
}

.topbar {
  background-color: var(--darkest);
  color: white;
  border-radius: 0.3rem;
  padding: 2rem 3rem;
  gap: 1rem;

  margin-bottom: 2rem;
}

.topbar .city {
  flex-grow: 1;
}

.overview {
  background-color: var(--strong);
  color: white;
  border-radius: 0.3rem;
  padding: 2rem 3rem;
  gap: 1rem;
}

h3.strong, h4.strong {
  color: var(--strong);
}

.skill-tags {
  gap: 0.5rem;
}

.skill-tags .tag {
  margin-left: 0;
  padding: 0.6rem 1rem;
  font-weight: bold;
}

.work-experience,
.education,
.skills,
.certifications {
  margin-bottom: 2rem;
}

.work-experience .skill,
.education .skill,
.certifications .skill,
.skills .skill {
  padding: 1rem 2rem 1.5rem 2rem;
}

.ResumePage-error {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.ResumePage-error-title {
  /* Error color */
  font-size: 24px;
  color: #800000;
}

.resume {
  &__highlights {
    &-wrapper {
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 1px;
      overflow: hidden;
    }

    &-item {
      background-color: #5ec58f;
      padding: 18px 25px;
      color: white;
      display: flex;
    }

    &-title {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
    }

    &-tag {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 11px;
      line-height: 120%;
      letter-spacing: 0.15em;
      color: #5ec58f;

      background-color: white;
      border-radius: 4px;
      padding: 10px 20px;
    }

    &-info {
      font-size: 17px;
      line-height: 135.5%;
      font-weight: 400;

      span {
        font-weight: 700;
      }
    }

  }
}