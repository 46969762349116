.plan-and-billing-page {
  padding-top: 60px;

  .invoice {
    max-width: 750px;
    width: 100%;
    border-radius: 17px;
    background: var(--faint-white, rgba(255, 255, 255, 0.4));
    padding: 64px 52px;
    margin: 0 auto;

    &__title {
      color: var(--fribl-blue, #1e4ca9);
      margin-bottom: 30px;
    }

    &__no-invoices {
      color: var(--fribl-blue, #1e4ca9);
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 19px;
    }
  }

  .invoice-item {
    padding: 24px 26px;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 16px;

    svg {
      margin-right: 24px;
    }

    &__date {
      flex: 1;
      color: var(--placeholder, #9a8787);
    }

    &__title {
      color: var(--alt-darker, #118db3);
    }

    &__amount {
      color: var(--strong-darker, #3fa06e);

      &--unpaid {
        color: var(--warning-orange);
      }
    }
  }

  .subscription {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    padding: 35px 56px;
    margin-bottom: 18px;
    border-radius: 8px;
    background: var(--darkest, #002060);

    &__content {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      color: var(--white);

      @media (max-width: 520px) {
        flex-direction: column;
        gap: 20px;
        align-items: start;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
    }

    &__plan {
      color: var(--strong, #5ec58f);
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }
  }
}
