.staff-selector {
  display: flex;
  gap: 5px;
  max-width: 100%;
  flex-wrap: wrap;

  &__item {
    font-family: IBM Plex Sans;
    cursor: pointer;

    border: none;
    border-radius: 9px;
    background: var(--white, #fff);
    flex: 1;
    height: 60px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 17px;
    font-style: normal;
    line-height: 120%;

    color: var(--placeholder, #9a8787);
    &.selected {
      background: var(--strong);

      color: white;
    }

    span:first-child {
      font-weight: 700;
    }

    span:last-child {
      font-weight: 400;
    }
  }
}
