.ats {
  background: #ededed;
  min-height: 100vh;
  padding: 110px 30px;

  @media (max-width: 768px) {
    padding: 64px 0px;
  }

  &__wrapper {
    max-width: 509px;
    margin: 0 auto;
    border-radius: 25px;
    background: var(--faint-white, rgba(255, 255, 255, 0.4));

    padding: 38px 49px;
    margin-bottom: 27px;

    @media (max-width: 768px) {
      padding: 30px;
    }
  }

  &__back-btn {
    border: none;
    background-color: none;
    cursor: pointer;

    margin-bottom: 33px;
  }

  .integration-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    button {
      align-self: flex-start;
    }

    &__title {
      font-weight: 700;
      color: var(--fribl-blue);
    }

    &__ats-list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 20px;
    }

    &__ats {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding: 12px;

      margin-bottom: 12px;
      border-radius: 9px;
      background: var(--white);

      img,
      svg {
        width: 100%;
        max-width: 200px;
        object-fit: contain;
      }
    }
  }

  .active-integration {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;

    p:first-child {
      color: var(--fribl-blue);
    }

    p:last-child {
      color: var(--placeholder);
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

}


.lever-config {
  align-self: stretch;

  margin-top: 12px;

  &__title {
    font-weight: 700;
    color: var(--fribl-blue);
    margin-bottom: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin: 24px 0px;

  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 36px;
  }
}