.signup-input {
  width: 100%;
  position: relative;
  margin: 6px 0px;

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 20px 10px;

    resize: none;

    color: var(--darkest, #002060);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    padding-right: 3%;


    border-radius: 9px;
    background: var(--white, #fff);
    outline: 6px solid transparent;
    border: none;

    font-family: "IBM Plex Sans", sans-serif;

    &:focus {
      outline: 6px solid var(--strong, #5ec58f);
    }

    &.error {
      outline: 6px solid var(--warning-orange, #f63);
    }

    &::placeholder {
      font-weight: 400;
      color: var(--placeholder, #9a8787);
    }

    // When input has values show label
    &:placeholder-shown+label {
      display: none;
    }

    &:not(:placeholder-shown)+label {
      display: block;
      position: absolute;
      top: 30px;
      right: 20px;
      transform: translateY(-50%);

      font-size: 13px;
      color: var(--placeholder, #9a8787);
      font-family: "IBM Plex Sans", sans-serif;
      background: white;
      padding: 4px;
    }
  }

  textarea {
    height: 114px;
  }

  svg {
    position: absolute;
    top: 30px;
    right: 0;
    transform: translate(50%, -50%);
  }

  &__error-message {
    color: var(--warning-orange, #f63);
    padding: 10px;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
  }

  &__success-text {
    color: var(--strong, #5ec58f);
    padding: 10px;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
  }

  &__helper-text {
    color: var(--placeholder, #9a8787);
    padding: 10px;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
  }

  &__password-toggle {
    color: var(--strong-darker, #3fa06e);
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;

    position: absolute;
    top: 30px;
    right: 14px;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  &__phone-wrapper {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__select-option {
    padding: 8px;

    resize: none;

    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--darkest, #002060);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    background: var(--white, #fff);
    outline: 6px solid transparent;
    border: none;

    font-family: "IBM Plex Sans", sans-serif;
  }
}