.table-container {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);


  .pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .pagination button {
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid #1e4ca9;
    background-color: #1e4ca9;
    color: #fff;
    cursor: pointer;
  }

  .pagination button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }

}

.table-container-title {
  margin-bottom: 12px;
  color: #1e4ca9;
}

.table-container-info {
  margin-bottom: 12px;
  color: #333;
}

.table-scroll {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

.table-actions,
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.table-header {
  margin-bottom: 12px;
  gap: 20px;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  padding: 16px;
  text-align: left;
  min-width: 100px;
  white-space: nowrap;
}

.table th {
  background-color: #1e4ca9;
  color: #fff;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.items-per-page {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.items-per-page span {
  margin-right: 10px;
}

.items-per-page select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.table-footer {
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin: 12px 0px;
}