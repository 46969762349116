.footer {
  background: var(--darkest);
  min-height: 336px;

  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 120px 30px;

  &__image {
    margin-bottom: 20px;
    width: 80px;
  }

  &__copyright {
    color: white;
    text-align: center;
  }
}
