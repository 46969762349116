.login-page {
  padding-top: 183px;
  padding-bottom: 120px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  background: #ededed;

  @media (max-width: 520px) {
    width: unset;
    max-width: 100%;
  }

  &__wrapper {
    max-width: 515px;
    margin: 0 auto;
    background: var(--faint-white, rgba(255, 255, 255, 0.4));
    border-radius: 25px;
    padding: 62px 52px;
    margin-bottom: 120px;

    @media (max-width: 768px) {
      padding: 42px 20px;
    }
  }

  &__title {
    color: var(--strong-darker, #3fa06e);
    font-size: 28px;
    font-weight: 700;
    line-height: 135%;
    margin-bottom: 33px;

    span {
      color: var(--fribl-blue, #1e4ca9);
    }
  }

  &__subtitle {
    color: var(--darkest, #002060);
    font-size: 17px;
    font-weight: 700;
    line-height: 120%;

    a {
      text-decoration: none;
      color: var(--strong-darker, #3fa06e);
    }
  }

  &__link {
    color: var(--strong-darker, #3fa06e);
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    padding: 10px;
    text-decoration: none;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 22px;
    margin-bottom: 60px;
  }

  button {
    width: 251px;
  }
}
