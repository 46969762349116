.select {
  width: 100%;
  margin: 6px 0px;
  position: relative;

  svg.success {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
  }

  label {
    position: absolute;
    top: 30px;
    right: 20px;
    transform: translateY(-50%);

    font-size: 13px;
    color: var(--placeholder, #9a8787);
    font-family: "IBM Plex Sans", sans-serif;

    background: white;
    padding: 4px;
  }

  &__option {
    padding: 12px 10px;
    margin-top: 1px;
    background-color: var(--lightest);
    color: var(--placeholder);
    transition: background-color 0.2s ease-in-out;

    &:first-child {
      margin-top: 0;
    }

    cursor: pointer;

    &--active {
      background-color: var(--placeholder-light);
    }
  }

  &__error-message {
    color: var(--warning-orange, #f63);
    padding: 10px;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
  }

  &__password-toggle {
    color: var(--strong-darker, #3fa06e);
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;

    position: absolute;
    top: 30px;
    right: 14px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
}