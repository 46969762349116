.buy-more {
  padding-top: 60px;

  .ran-out {
    max-width: 1065px;
    margin: 0 auto;
    padding: 20px 30px;

    &__wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 16px;
      background: #f4f4f4;
      padding: 48px 31px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .billing-items {
    position: relative;
    z-index: 2;
    width: 100%;
    background: var(--lightest, #ededed);

    &__wrapper {
      max-width: 1065px;
      margin: 0 auto;
      padding: 20px 30px;
    }

    &__title {
      color: var(--darkest);
      margin-bottom: 34px;
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 27px;
    }
  }

  .billing-item {
    display: flex;
    flex-direction: column;
    position: relative;

    width: 317px;

    &__saves {
      color: var(--darkest, #002060);
      font-family: "IBM Plex Sans";
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;

      &--popular {
        color: var(--strong, #5ec58f);
      }

      &--lowcost {
        color: var(--fribl-blue, #1e4ca9);
      }
    }

    &__badge {
      padding: 11px 17px;
      color: var(--white, #fff);
      font-size: 17px;
      font-weight: 700;
      line-height: 120%;

      position: absolute;
      top: 0;
      right: 10px;
      transform: translateY(-50%);

      &--popular {
        background: var(--strong, #5ec58f);
      }

      &--lowcost {
        background: var(--fribl-blue, #1e4ca9);
      }
    }

    &__content {
      background: #f4f4f4;
      padding: 48px 31px;

      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;
      min-height: 281px;
    }

    &__title {
      color: var(--darkest, #002060);
      font-size: 28px;
      font-weight: 700;
      line-height: 135%;
    }

    &__text {
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;

      &--highlighted {
        font-weight: 700;
      }
    }

    &__price-wrapper {
      flex: 1;
      display: flex;
      align-items: flex-end;
    }

    &__price {
      display: flex;
      align-items: baseline;
      color: #806363;
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;

      span {
        font-size: 28px;
        font-weight: 700;
        line-height: 135%;
        margin-right: 12px;
      }
    }

    &__button-wrapper {
      padding: 48px 31px;
      min-height: 184px;
      background: #fff;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-end;
      gap: 16px;

      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    }
  }
}
