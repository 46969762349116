.add-job-spec {
  max-width: 1000px;
  width: 95%;
  margin: 68px auto;
  padding: 0px 30px;

  &__title {
    color: var(--darkest, #002060);
    text-align: center;

    span {
      color: var(--strong, #5ec58f);
    }
  }

  &__buttons {
    display: flex;
    gap: 8px;

    button {
      border-radius: 9px;
      border: none;
      padding: 18px 10px;

      background: var(--lightest);

      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 400;
      font-size: 17px;
      line-height: 135.5%;
      color: var(--placeholder);
      cursor: pointer;

      &.selected {
        background: var(--strong-darker);
        color: var(--white)
      }
    }
  }

  &__form {
    margin-top: 10px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__content {
    padding: 52px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 25px;
    background: #f4f4f4;
    margin-top: 33px;

    textarea {
      height: 411px;
    }
  }

  &__pull-from-ats {
    padding-bottom: 40px;
    width: 100%;

    .h4 {
      color: var(--fribl-blue)
    }
  }

  &__ats-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;


    gap: 8px;
    margin-top: 8px;




  }

  &__info {
    color: var(--strong-darker, #3fa06e);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__link {
    color: var(--alt-darker, #118db3);
    text-decoration: none;
  }

  &__topbar {
    padding: 2rem;
    background-color: var(--darkest);
    color: white;
    max-width: 600px;
    width: 100%;
    border-radius: 0.5rem;
    gap: 2rem;

    margin-top: 40px;
  }

  .first,
  .second,
  .third,
  .fourth,
  .fifth {
    height: 0px;
    overflow: hidden;
  }

  .first {
    animation: grow 1s ease 1s forwards;
  }

  .second {
    animation: grow 1s ease 1.5s forwards;
  }

  .third {
    animation: grow 1s ease 2s forwards;
  }

  .fourth {
    animation: grow 1s ease 2.5s forwards;
  }

  .fifth {
    animation: grow 1s ease 3s forwards;
  }

  @keyframes grow {
    to {
      height: 3rem;
    }
  }
}