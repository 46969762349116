@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  --darkest: #002060;
  --strong: #5ec58f;
  --strong-darker: #3fa06e;
  --light: #002060b3;
  --lightest: #ededed;

  --fribl-blue: #1e4ca9;
  --alt: #18a8d5;
  --alt-lighter: #8CD9F2;
  --alt-darker: #118db3;
  --white: #fff;
  --error: red;
  --warning-orange: #f63;
  --warning-secondary: #FF6633;
  --placeholder: #9a8787;
  --faint-white: rgba(255, 255, 255, 0.4);
  --placeholder-light: #d1cfcf;
  --faintest-white: rgba(255, 255, 255, 0.11);

  font-family: "IBM Plex Sans", sans-serif;
  color: var(--darkest);
}

h4 {
  font-size: 1rem;
}

.flex {
  display: flex;

  &-gap {
    gap: 8px;
  }

  &-col {
    display: flex;
    flex-direction: column;
  }

  &-baseline {
    display: flex;
    align-items: baseline;
  }

  &-wrap {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }

  &.items-center {
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }
}

.middle {
  display: grid;
  place-items: center;
}

.btn {
  background: none;
  cursor: pointer;
  padding: 15px 22px;
  outline: 1px solid currentColor;
  border-radius: 10rem;
  text-decoration: none;

  font-family: Lato;
  font-size: 21px;
  font-weight: 400;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
  border: none;
  outline: none;

  transition: all 0.2s;

  font-family: 'IBM Plex Sans', sans-serif;


  &--nav {
    @media (max-width: 768px) {
      padding: 10px 22px;
    }
  }

  &__text {
    opacity: 1 !important;
  }

  .diff {
    color: var(--darkest);
    opacity: 1;
    padding: 0;
  }

  color: #3fa06e;
  background: white;

  &--small {
    font-size: 17px;
  }

  &--icon {
    font-size: 17px;
    padding: 0px;
  }

  .text-normal {
    font-weight: 400;
    opacity: 1;
  }

  &.text {
    outline: none;
    background-color: transparent;
    color: white;
    font-weight: 700;

    &.disabled {
      pointer-events: none;
    }

    &:hover {
      color: var(--strong-darker);
    }

    &.active {
      color: var(--strong-darker);
    }
  }

  &.primary {
    outline: none;
    background-color: var(--strong);
    color: white;
    font-weight: 700;

    &.disabled {
      background: #e1e1e1;
      pointer-events: none;
      color: var(--white);
    }

    &:hover {
      background-color: var(--strong-darker);
      color: white;
    }
  }

  &.ghost {
    outline: none;
    background-color: var(--white);
    color: var((--strong-darker));
    font-weight: 700;

    &.disabled {
      background: #e1e1e1;
      pointer-events: none;
      color: var(--white);
    }

    &:hover {
      background-color: var(--strong-darker);
      color: white;
    }
  }

  &.warning {
    outline: none;
    background-color: var(--warning-orange);
    color: white;
    font-weight: 700;

    &.text {
      color: var(--warning-secondary);
      background: none;
      font-weight: 400;
    }

    &.disabled {
      background: #e1e1e1;
      pointer-events: none;
      color: var(--white);
    }


  }

  &.error {
    outline: none;
    background-color: var(--error);
    color: white;
    font-weight: 700;

    &.text {
      color: var(--error);
      background: none;
      font-weight: 400;
    }

    &.disabled {
      background: #e1e1e1;
      pointer-events: none;
      color: var(--white);
    }
  }

  &.secondary {
    outline: none;
    background-color: var(--darkest);
    color: white;
    font-weight: 700;

    &.disabled {
      pointer-events: none;
      background-color: darken($color: #002060, $amount: 20);
    }

    &:hover {
      background-color: var(--strong-darker);
      color: white;
    }
  }

  &.tertiary {
    outline: none;
    background: var(--lightest, #ededed);
    color: var(--strong, #5ec58f);
    font-weight: 700;

    &.disabled {
      pointer-events: none;
      background-color: darken($color: #002060, $amount: 20);
    }

    &:hover {
      background-color: var(--strong-darker);
      color: white;
    }
  }

  // 4th variant
  &.quaternary {
    outline: none;
    background: var(--fribl-blue, #1e4ca9);
    color: var(--white);
    font-weight: 700;

    &.disabled {
      pointer-events: none;
      background-color: darken($color: #1e4ca9, $amount: 20);
    }

    &:hover {
      background-color: var(--darkest);
      color: white;
    }
  }

  // 5th variant
  &.quinary {
    background: var(--alt, #18a8d5);
    padding: 15px;
    color: #fff;
    font-weight: 700;

    &.disabled {
      pointer-events: none;
      background-color: darken($color: #1e4ca9, $amount: 20);
    }

    &:hover {
      background-color: var(--fribl-blue);
      color: white;
    }
  }

  // 6th variant
  &.senary {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    color: var(--white);
    font-weight: 700;

    &.disabled {
      pointer-events: none;
      background-color: darken($color: #1e4ca9, $amount: 20);
    }

    &:hover {
      background-color: var(--alt-darker);
      color: white;
    }
  }
}

.btn span {
  opacity: 0.5;
}

/* =============== */

.app {
  background-color: var(--lightest);

  display: grid;
  grid-template-columns: 346px minmax(0, 1fr);
  max-height: 100vh;
  max-width: 100%;

  overflow-y: scroll;
}

.content-container {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: auto;
  max-width: 100%;
}

.page {
  height: 100%;
  overflow-y: auto;
}

.error-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 20px;
}

@media (max-width: 768px) {
  .app {
    grid-template-columns: 1fr;
  }

  .content-container {
    max-height: unset;
    min-height: calc(100vh - 81px);
  }
}

.cv-render {
  max-width: 100%;
  overflow-x: auto;

  #pdf-download {
    display: none;
  }
}