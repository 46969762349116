.sidebar {
  height: 100vh;
  overflow-y: auto;

  left: 0;
  top: 0;
  box-sizing: border-box;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  border-right: 1px solid #f7f7f7;
  background-color: var(--darkest);
  width: 346px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    min-width: 100%;
    height: unset;
    width: 100%;

    border-right: none;
    border-bottom: 1px solid #f7f7f7;
  }

  &__imagecontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 52px;
    padding-right: 44px;
  }

  &__logo {
    width: 80px;
  }

  &__user-icon {
    background: none;
    cursor: pointer;
    border: none;
    color: var(--alt, #18a8d5);
    font-size: 17px;
    font-weight: 700;
    line-height: 135.5%;
    display: flex;
    align-items: center;
    gap: 10px;

    &--desktop {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: 768px) {
        display: flex;
      }
    }
  }

  &__toggle {
    display: none;
    cursor: pointer;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__content,
  &__content-mobile {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }

  &__content {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__content-mobile {
    position: fixed;
    z-index: 3;
    top: 0;
    right: -400px;
    width: 300px;
    height: 100vh;
    transition: all 0.3s;

    background: var(--darkest);

    border-left: 1px solid #f7f7f7;
    overflow-y: scroll;

    &--visible {
      right: 0;
    }
  }

  @media (max-width: 768px) {
    display: flex;
  }

  &__mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 2rem;
  }

  &__backdrop {
    display: none;
    background: var(--darkest);
    opacity: 0.6;

    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100vh;
    height: 100vh;
    cursor: pointer;

    @media (max-width: 768px) {
      &--visible {
        display: block;
      }
    }
  }

  // Components
  .job-match {
    flex: 1;
    margin-top: 120px;
    padding: 0 45px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
      color: var(--strong, #5ec58f);
      padding: 15px;
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;

      display: flex;
      align-items: center;
      gap: 10px;

      position: relative;

      &:last-child {
        &::after {
          display: none;
        }
      }

      &::after {
        content: " ";
        width: 2px;
        height: 29px;

        position: absolute;
        left: 27px;
        top: 40px;

        background: rgba(255, 255, 255, 0.11);
      }

      span:first-child {
        font-size: 13px;
        font-weight: 700;
        display: flex;
        width: 25px;
        height: 25px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        line-height: 1;
        background: var(--faintest-white, rgba(255, 255, 255, 0.11));
      }

      &--active {
        color: #fff;
        font-weight: 700;
      }

      &--active {
        span:first-child {
          background: var(--white, #fff);
          color: var(--darkest, #002060);
        }
      }

      &--completed {
        &::after {
          background: var(--strong, #5ec58f);
        }

        span:first-child {
          background: var(--strong, #5ec58f);
        }
      }

      &--highlight {
        background: var(--strong, #5ec58f);
        border-radius: 1000px;
        font-weight: 700;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .general-settings {
    flex: 1;
    margin-top: 25px;
    padding: 0 45px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
      cursor: pointer;

      color: var(--white, #fff);
      font-family: IBM Plex Sans;
      font-size: 17px;
      font-weight: 700;
      line-height: 120%;
      text-decoration: none;
      border: none;
      background: none;
      text-align: left;
      padding: 15px;
    }
  }

  .matches {
    flex: 1;
    margin-top: 13px;

    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 40px;

    &__info {
      font-size: 16px;
      line-height: 120%;
      color: var(---white, #fff);
      padding: 22px 16px 22px 44px;
    }

    &__job-ad {
      padding: 22px 18px 22px 44px;
      color: var(--white, #fff);
      font-family: IBM Plex Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 135.5%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      text-decoration: none;

      span {
        color: var(--white, #fff);
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        opacity: 0.6;
      }

      &--selected {
        background: var(--faintest-white, rgba(255, 255, 255, 0.11));
      }
    }

    &__toolbar {
      padding: 23px 18px 23px 44px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }

    &__recent {
      color: var(--alt);
      font-weight: 700;
      font-size: 13px;
      line-height: 120%;


    }

    &__search {
      font-family: inherit;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      text-align: right;

      color: #FFFFFF;
      background: none;
      outline: none;
      border: none;
      opacity: 0.6;
    }

    &__candidates {
      margin-bottom: 44px;
    }

    &__menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      text-decoration: none;
      padding: 20px 18px 20px 44px;

      background: rgba(255, 255, 255, 0.11);
      margin: 2px 0px;
      width: 100%;
      color: var(--alt);
      cursor: pointer;

      font-size: 13px;
      font-weight: 700;

      span {
        font-weight: 400;
        color: var(--white);
        opacity: 0.6;
      }

      &--selected {
        background: var(--white);
      }
    }

    &__candidate {
      padding: 11px 18px 11px 44px;

      background: rgba(255, 255, 255, 0.11);
      margin: 2px 0px;
      width: 100%;
      color: var(--darkest, #002060);
      border: none;

      color: var(--white, #fff);
      cursor: pointer;


      &--selected {
        background: var(--white, #fff);
        padding: 25px 18px 19px 44px;
        cursor: unset;


        div:first-child {
          span:first-child {

            font-size: 17px;
            color: var(--darkest, #002060);
          }

          span:last-child {
            background: var(--strong-darker, #3fa06e);
          }
        }
      }


      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        span:first-child {
          font-size: 13px;
          font-weight: 700;
          line-height: 120%;
          flex: 1;
          text-align: start;
        }

        span:last-child {
          font-size: 17px;
          font-weight: 700;
          line-height: 1;

          display: flex;
          width: 33px;
          height: 33px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 29px;
          background: var(--faintest-white, rgba(255, 255, 255, 0.11));
        }

      }

      &-actions {
        display: flex;
        gap: 3px;
        align-items: start;
        margin-top: 12px;

      }

      &-action-item {
        background: var(--lightest);
        padding: 10px;
        cursor: pointer;

        font-family: inherit;
        font-size: 13px;
        line-height: 120%;

        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: var(--placeholder);
        border-radius: 9px;
        border: none;
        outline: none;

        &--selected {
          font-weight: 700;
          color: var(--darkest);
        }

      }
    }
  }
}