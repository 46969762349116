.create-account {
  padding: 183px 30px 120px 30px;

  min-height: 100vh;
  background: #ededed;

  @media (max-width: 520px) {
    width: unset;
    max-width: 100%;
  }

  &__wrapper {
    max-width: 515px;
    margin: 0 auto;
    background: var(--faint-white, rgba(255, 255, 255, 0.4));
    border-radius: 25px;
    padding: 62px 52px;
    margin-bottom: 120px;

    @media (max-width: 768px) {
      padding: 42px 20px;
    }
  }

  &__title {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 28px;
    font-weight: 700;
    line-height: 135%;

    span {
      color: var(--strong-darker, #3fa06e);
    }
  }

  &__helper {
    color: var(--placeholder, #9a8787);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__label {
    color: var(--darkest, #002060);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 11px;
  }

  &__content {}

  &__button-wrapper {
    max-width: 515px;
    margin: 0 auto;
  }

  &__back-btn {
    background: none;
    color: var(--fribl-blue);
    font-weight: 600;
    font-size: 22px;

    outline: none;
    border: none;
    cursor: pointer;

    margin-bottom: 40px;
    text-decoration: none;

    svg {
      margin-right: 10px;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  // Components
  .billing-options {
    max-width: 1186px;
    margin: 0 auto;

    display: grid;
    gap: 13px;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  .billing-option {
    background: #fff;
    padding: 48px 22px;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 16px;

    &__ideal {
      position: absolute;
      left: 22px;
      top: 0;
      transform: translateY(-50%);
      padding: 11px 17px;

      color: var(--white, #fff);
      font-size: 17px;
      font-weight: 700;
      line-height: 120%;

      background: var(--strong, #5ec58f);
    }

    &__title {
      color: var(--darkest, #002060);
      font-size: 28px;
      font-weight: 700;
      line-height: 135%;
    }

    &__texts {
      flex: 1;

      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 48px;
    }

    &__text {
      color: var(--darkest, #002060);
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;

      &--highlight {
        color: var(--strong-darker, #3fa06e);
      }

      &--bold {
        font-weight: 700;
      }
    }
  }

  .sent-to-email {
    p {
      color: var(--darkest, #002060);
      font-family: IBM Plex Sans;
      font-size: 17px;
      font-style: normal;
      line-height: 120%;

      margin-bottom: 22px;

      span {
        font-weight: 700;
      }
    }

    &__button {
      width: 251px;
      background-color: var(--strong-darker, #3fa06e) !important;
    }
  }
}