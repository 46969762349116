.matches-page {
  width: 100%;
  background-color: var(--lightest);
  min-height: 100vh;

  padding: 3rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  &__loading {
    display: grid;
    place-items: center;
  }

  h1 {
    padding-bottom: 2rem;
  }

  &__card {
    max-width: 486px;
    margin: 0 auto;
    margin-top: 120px;
    background: var(--white);
    border-radius: 25px;
    padding: 52px;

    a {
      width: 220px;

      span {
        opacity: 1;
      }

      span span {
        font-weight: 300;
      }
    }
  }

  &__card-text {
    font-weight: 400;
    margin: 60px 0px 40px 0px;
  }

  &__info-container {
    background-color: var(--darkest);
    color: white;
    padding: 3rem;
    border-radius: 0.5rem;
    height: min-content;
    margin: 0 auto;

    max-width: 706px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    a {
      color: currentColor;
    }
  }

  &__content {

    max-width: 706px;
    margin: 0 auto;
  }

  &__header {
    background-color: var(--darkest);
    border-radius: 0.5rem;
    width: 100%;
    padding: 1.5rem;
    gap: 2rem;
    margin-bottom: 12px;

    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      gap: 0.5rem;


    }

    .score {
      width: 5rem;
      height: 5rem;
      font-weight: 700;
      color: var(--white);

      @media (max-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &__header-info {
    flex: 1
  }

  &__name {
    flex: 1;
    color: var(--white);

    // Even child
    &:nth-child(even) {
      color: var(--strong)
    }
  }

  &__nav-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    flex: 1;
  }

  &__nav-btn {
    border-radius: 30px;
    padding: 3px 12px;
    cursor: pointer;

    font-family: inherit;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    border: none;
    outline: none;
    background: none;

    color: var(--alt-darker);

    &.selected {
      background: var(--alt-darker, #118DB3);
      color: var(--white);
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__highlights {
      &-wrapper {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 1px;
        overflow: hidden;
      }

      &-item {
        background-color: #5ec58f;
        padding: 18px 25px;
        color: white;
        display: flex;
      }

      &-title {
        font-family: "IBM Plex Sans";
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
      }

      &-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }

      &-tag {
        font-family: "IBM Plex Sans";
        font-weight: 700;
        font-size: 11px;
        line-height: 120%;
        letter-spacing: 0.15em;
        color: #5ec58f;

        background-color: white;
        border-radius: 4px;
        padding: 10px 20px;
      }

      &-info {
        font-size: 17px;
        line-height: 135.5%;
        font-weight: 400;

        span {
          font-weight: 700;
        }
      }

    }
  }
}