.landing-page-navbar {
  color: white;
  position: fixed;
  top: -1px;
  left: 0;
  max-width: 100vw;
  width: 100%;
  background-color: transparent;
  transition: all 0.2s;
  z-index: 999;
  height: 100px;

  &--sticky {
    background-color: var(--fribl-blue);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  &__wrapper {
    max-width: 1189px;
    padding: 20px 23px;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 auto;
  }

  &__desktop,
  &__mobile {
    a,
    button {
      font-family: Lato;
      font-size: 17px;
      font-weight: 700;
      color: white;
      text-decoration: none;
      transition: all 0.2s;

      &:hover {
        color: #3fa06e;
      }
    }
  }

  &__desktop {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex: 1;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    @media (max-width: 1024px) {
      flex: 1;
      margin-right: 15px;
    }

    .secondary-link {
      color: #fff;
      font-size: 17px;
      text-decoration: none;

      &:hover {
        color: #3fa06e;
      }
    }
  }

  &__sticky-buttons {
    position: sticky;
    display: none;
    flex-direction: column;
    row-gap: 10px;
    top: 100%;

    border-top: 1px solid var(--lightest);
    padding: 1.5rem 2rem 1rem 2rem;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  &__toggle {
    justify-content: flex-end;

    display: none;
    cursor: pointer;

    @media (max-width: 1024px) {
      display: flex;
    }
  }

  &__mobile {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 1rem;
  }

  &__content-mobile {
    position: fixed;
    z-index: 3;
    top: 0;
    right: -400px;
    width: 300px;
    height: 100vh;
    transition: all 0.3s;

    background: var(--darkest);
    border-left: 1px solid #f7f7f7;

    .mobile-logo {
      background: none;
      padding: 0;

      &:hover {
        background: none;
      }

      img {
        width: 80px;
      }
    }

    &--visible {
      right: 0;
    }

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    padding-bottom: 0rem;

    div[role="button"] {
      cursor: pointer;
    }
  }

  &__backdrop {
    display: none;
    background: var(--darkest);
    opacity: 0.6;

    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100vh;
    height: 100vh;
    cursor: pointer;

    @media (max-width: 1024px) {
      &--visible {
        display: block;
      }
    }
  }

  &__desktop-button {
    &.btn {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__select-language {
    display: none;
    align-items: center;
    cursor: pointer;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  &__language-icon {
    width: 20px;
    height: auto;
    margin-right: 0.5rem;
  }

  header {
    justify-content: space-between;
  }

  .logo {
    margin-right: 2rem;

    img {
      width: 82px;
    }

    @media (max-width: 520px) {
      margin-right: 1rem;
    }
  }

  a {
    font-weight: bold;

    &.selected {
      color: var(--strong);
    }
  }
}
