.select-cv {
  max-width: 813px;
  margin: 30px auto;
  padding: 0px 30px;

  &__banner {
    padding: 35px 50px;
    background: var(--darkest, #002060);
    border-radius: 8px;

    position: sticky;
    top: 29px;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px
  }

  &__info {
    color: #fff;

    span {
      color: var(--strong);
    }
  }

  &__credit-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;

    p:last-child {
      font-size: 12px;
      text-align: right;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 10px;

    flex: 1;
    justify-content: flex-end;

    color: var(--white);
  }

  &__cv-wrapper {
    display: flex;
    flex-direction: column;
    gap: 11px;
    align-items: stretch;
    margin-top: 38px;
  }

  // Components
  .cv-item {
    background: var(--white, #fff);
    padding: 19px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    height: 57px;
    cursor: pointer;
    position: relative;

    &--readonly {
      cursor: text;
    }

    &--no-bg {
      background: none;
    }

    &--pending {
      background: rgba(24, 168, 213, 0.11);
    }

    &--with-source {
      margin-top: 8px;
    }

    &__source {
      position: absolute;
      left: 24px;
      top: 0;
      transform: translateY(-50%);
      font-size: 12px;
      background-color: var(--fribl-blue);
      padding: 3px 6px;
      border-radius: 3px;
      color: var(--white);

    }

    &__name {
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
      color: #000;

      &--selected {
        color: var(--alt-darker, #118db3);
      }
    }

    .checkbox {
      margin: 0 -10px;
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 16px;
      flex: 1;
    }

    &__status {
      color: var(--placeholder, #9a8787);

      &--selected {
        color: var(--alt-darker, #118db3);
      }

      &--failed {
        color: var(--warning-orange, #f63);
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__link {
      color: var(--placeholder, #9a8787);
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      text-decoration: none;
    }
  }

  .no-cv {
    padding: 50px 25px;
    border-radius: 6px;
    background: var(--fribl-blue, #1e4ca9);

    &__title {
      color: var(--white, #fff);
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 135%;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 35px 56px;

      border-radius: 8px;
      background: var(--darkest, #002060);

      @media (max-width: 520px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__action-text {
      flex: 1;

      color: var(--white, #fff);
      font-family: "IBM Plex Sans";
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;

      span {
        color: var(--strong, #5ec58f);
      }
    }

    &__file {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
    }

    &__steps {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 8px;

      @media (max-width: 520px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    &__step-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 33px;

      gap: 32px;
    }

    &__step-image-wrapper {
      display: flex;
      align-items: flex-end;
      max-width: 100%;
    }

    &__step-title {
      color: var(--white, #fff);
      font-size: 16px;
      font-weight: 400;
      line-height: 149.5%;
      text-align: center;
    }
  }

  .info {
    padding: 22px 56px;
    border-radius: 8px;
    background: var(--alt, #18A8D5);

    margin-top: 12px;

    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;

    &__text {
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: var(--white);

      &--highlight {
        font-weight: 700;
      }

      a {
        color: var(--white);
        text-decoration: underline;
      }
    }
  }

  .upload-cv-actions {
    position: relative;
    // width: 150px;

    &__trigger {
      cursor: pointer;
      border-radius: 1000px;
      overflow: hidden;
      background: var(--alt-darker);

      color: #fff;
      font-size: 17px;
      font-weight: 700;
      line-height: 120%;
      flex: 1;
      text-align: center;
      width: 100%;
      text-align: center;

      display: flex;
      align-items: center;

      justify-content: center;
      padding: 15px 20px;
      border: none;
      font-family: 'IBM Plex Sans', sans-serif;
      transition: all .2s;


      &:hover {
        background: var(--fribl-blue);
      }

      &--text {
        color: var(--alt-lighter);
        font-weight: 400;
        background: none;

        &:hover {
          background: none;
        }
      }

      &--main {
        padding: 15px 20px;
      }


    }

    &__content {
      position: absolute;
      z-index: 3;
      top: calc(100% + 10px);
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 1px;

      border-radius: 9px;
      animation: slideIn 0.3s ease-in-out;
      background-color: #333;
      overflow: hidden;

      width: 338px;

      @keyframes slideIn {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }

        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &--open {
      .dropdown__content {
        display: block;
      }
    }

    &__item {
      margin: 0;
      border: none;
      padding: 14px 22px;
      color: #fff;
      font-size: 17px;
      font-weight: 700;
      line-height: 120%;
      background: var(--alt, #18a8d5);
      cursor: pointer;
      text-align: start;
      text-decoration: none;
      transition: all 0.2s;

      &:hover {
        background: var(--fribl-blue);
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 12px;


    input {
      border: none;
      background: none;
      font-family: 'IBM Plex Sans', sans-serif;

      text-align: right;
      width: 140px;
      padding: 8px;
      outline: none;

      font-size: 16px;
      color: var(--placeholder);
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: var(--placeholder)
    }

    span {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: var(--darkest);
      cursor: text;
    }

    button {
      cursor: pointer;
      border: none;
      background: none;
      padding: 8px;
    }
  }

  .sort {
    margin-left: 12px;
    display: flex;
    align-items: center;

    &__label {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: var(--placeholder)
    }

    &__button {
      padding: 10px;
      border: none;
      background: none;

      display: flex;
      align-items: center;
      gap: 10px;

      font-family: 'IBM Plex Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: var(--darkest);

      cursor: pointer;

      &--asc {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}