.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;

  &.small {
    width: 20px;
    height: 20px;
  }

  &__container {
    display: flex;
    justify-content: center;
    margin: 12px 0px;

    &.small {
      margin: 0px;
    }
  }

  &.small::after {
    width: 20px;
    height: 20px;
    margin: 0px;
  }

  &--primary {
    &::after {
      border-color: var(--primary) transparent var(--primary) transparent;
    }
  }

  &:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 4px;
    border-radius: 50%;
    border: 3px solid var(--darkest);
    border-color: var(--darkest) transparent var(--darkest) transparent;
    animation: spinning-animation 1.2s linear infinite;
  }

  &--primary {
    &::after {
      border-color: var(--darkest) transparent var(--darkest) transparent;
    }
  }

  &--secondary {
    &::after {
      border-color: var(--white, #fff) transparent var(--white, #fff)
        transparent;
    }
  }
}

@keyframes spinning-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
