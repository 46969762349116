.pending-payment {
  background: var(--lightest, #ededed);
  padding-top: 200px;

  @media (max-width: 768px) {
    padding-top: 150px;
  }

  &__wrapper {
    max-width: 670px;
    margin: 0px auto;
    height: auto;
    display: flex;
    flex-direction: column;
    color: var(--darkest);
    min-height: 100vh;
  }

  &__link {
    color: var(--alt, #18a8d5);
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 48px;

    @media (max-width: 678px) {
      margin: 16px;
    }
  }

  &__content {
    padding: 61px 56px;
    border-radius: 21px;
    background: #fff;
    box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.15);

    @media (max-width: 678px) {
      padding: 32px 24px;
    }
  }

  &__submit {
    cursor: pointer;
    width: 100%;
    margin-top: 16px;
    border-radius: 8px;
    color: #fff;

    font-family: IBM Plex Sans;
    border: none;
    outline: none;
    padding: 16px;
    font-size: 17px;
    font-weight: 700;
    line-height: 120%;
    background: var(--darkest);
  }

  .payment-confirmation {
    display: flex;
    flex-direction: column;
    gap: 22px;

    &__title {
      color: var(--strong-darker, #3fa06e);
      font-size: 28px;
      font-weight: 600;
      line-height: 136.5%;

      span {
        color: var(--darkest, #002060);
      }
    }

    &__subscription-info {
      color: var(--darkest, #002060);
      font-size: 17px;
      font-weight: 700;
      line-height: 120%;
    }

    &__welcome-text {
      color: var(--darkest, #002060);
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 50px;
    }

    &__next-btn {
      max-width: 261px;
    }

    &__next {
      color: var(--darkest, #002060);
      padding-left: 0;
      opacity: 1;
    }
  }
}
