.checkbox {
  display: flex;
  align-items: flex-start;
  gap: 19px;

  position: relative;
  cursor: pointer;
  user-select: none;
  font-family: "Open Sans", sans-serif;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  svg {
    min-width: 33px;
    max-width: 33px;
  }

  &__label {
    color: #3ac793;
    font-family: IBM Plex Sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;

    a {
      color: #3ac793;
    }
  }

  &--primary {
    margin: 6px 0px;

    svg {
      color: #5ec58f;
    }

    .checkbox__label {
      color: #3ac793;

      a {
        color: #3ac793;
      }
    }
  }

  &--secondary {
    svg {
      color: var(--alt, #18a8d5);
      width: 19px;
      height: 19px;
    }

    .checkbox__label {
      color: var(--alt, #18a8d5);

      a {
        color: var(--alt, #18a8d5);
      }
    }
  }

  &--tertiary {
    svg {
      color: var(--alt, #18a8d5);
      width: 19px;
      height: 19px;
    }
  }
}
