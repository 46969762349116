.react-ga {
  position: fixed;
  left: 14px;
  bottom: 14px;
  width: calc(100% - 28px);
  z-index: 100;

  padding: 30px;
  border-radius: 9px;

  background: var(--darkest);
  gap: 12px;

  color: var(--white);

  &__content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    gap: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
      gap: 20px;
    }
  }

  a {
    color: inherit;
  }

  &__actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
}
