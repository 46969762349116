.choose-pricing {
  display: flex;
  flex-direction: column;
  padding-top: 99px;
  align-items: center;

  background-color: #ededed;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    margin: 120px 0px;
  }

  .hero {
    padding-top: 96px;
    padding-bottom: 235px;
    width: 100%;
    position: relative;

    @media (max-width: 768px) {
      padding-top: 21px;
      padding-bottom: 115px;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }

    &__wrapper {
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      position: relative;
      z-index: 2;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      position: relative;
      z-index: 2;

      @media (max-width: 768px) {
        margin-top: 20px;
        padding-bottom: 100px;
        align-items: flex-start;

        h1 {
          margin-bottom: 8px;
        }

        p,
        h1 {
          text-align: left;
        }
      }
    }

    &__title {
      color: var(--white);
      text-align: center;

      span {
        color: var(--strong, #5ec58f);
      }
    }

    &__text {
      text-align: center;
      color: #fff;
      font-size: 19px;
      font-weight: 400;
      line-height: 120%;

      span {
        font-weight: 700;
      }

      a {
        color: var(--strong, #5ec58f);
        text-decoration: none;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__back-btn {
    background: none;
    color: var(--white);
    font-weight: 600;
    font-size: 22px;

    margin-bottom: 12px;
    display: block;

    outline: none;
    border: none;
    cursor: pointer;

    margin-bottom: 40px;
    text-decoration: none;

    svg {
      margin-right: 10px;
    }
  }

  .billing-items {
    position: relative;
    z-index: 2;
    width: 100%;
    background: var(--lightest, #ededed);

    &__wrapper {
      max-width: 1065px;
      margin: 0 auto;
      padding: 20px 30px;
    }

    &__content {
      margin-top: -200px;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 27px;

      @media (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 57px;
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin-top: -100px;
      }
    }
  }

  .billing-item {
    display: flex;
    flex-direction: column;
    position: relative;

    &__saves {
      color: var(--darkest, #002060);
      font-family: "IBM Plex Sans";
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;

      &--popular {
        color: var(--strong, #5ec58f);
      }

      &--lowcost {
        color: var(--fribl-blue, #1e4ca9);
      }
    }

    &__badge {
      padding: 11px 17px;
      color: var(--white, #fff);
      font-size: 17px;
      font-weight: 700;
      line-height: 120%;

      position: absolute;
      top: 0;
      right: 10px;
      transform: translateY(-50%);

      &--popular {
        background: var(--strong, #5ec58f);
      }

      &--lowcost {
        background: var(--fribl-blue, #1e4ca9);
      }
    }

    &__content {
      background: #f4f4f4;
      padding: 48px 31px;

      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;
      min-height: 281px;
    }

    &__title {
      color: var(--darkest, #002060);
      font-size: 28px;
      font-weight: 700;
      line-height: 135%;
    }

    &__text {
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;

      &--highlighted {
        font-weight: 700;
      }
    }

    &__price-wrapper {
      flex: 1;
      display: flex;
      align-items: flex-end;
    }

    &__price {
      display: flex;
      align-items: baseline;
      color: #806363;
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;

      span {
        font-size: 28px;
        font-weight: 700;
        line-height: 135%;
        margin-right: 12px;
      }
    }

    &__button-wrapper {
      padding: 48px 31px;
      min-height: 184px;
      background: #fff;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-end;
      gap: 16px;

      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    }

    &__saves {
      color: var(--darkest, #002060);
      font-size: 21px;
      font-weight: 700;
      line-height: 135%;

      &--popular {
        color: var(--strong-darker, #3fa06e);
      }

      &--lowcost {
        color: var(--fribl-blue, #1e4ca9);
      }
    }
  }

  .enterprise-price {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1024px) {
      margin-bottom: 96px;
    }

    @media (max-width: 768px) {
      margin-top: -100px;
    }

    &__content {
      max-width: 491px;
      padding: 48px 31px;
      background-color: var(--white);

      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__title {
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }

    &__text {
      color: var(--darkest, #002060);
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;
    }

    &__button {
      margin-top: 32px;
      background: var(--darkest, #002060);
      align-self: stretch;

      padding: 15px 16px;

      color: var(--white, #fff);
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;
      border: none;
      outline: none;

      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;

      & > span {
        flex: 1;

        span {
          font-weight: 700;
        }
      }
    }
  }
}

p, span {
  &.green {
    color: var(--strong-darker, #3FA06E);

    font-size: 28px;
    font-style: normal;
    line-height: 135%; /* 37.8px */
  }

  &.normal {
    font-size: 17px;
    line-height: 135.5%; /* 23.0px */
  }

  &.small {
    font-size: 13px;
    line-height: 120%; /* 15.6px */
  }
}

