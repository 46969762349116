.manage-team {
  background: #ededed;
  min-height: 100vh;
  padding: 110px 30px;

  @media (max-width: 768px) {
    padding: 64px 0px;
  }

  &__wrapper {
    max-width: 758px;
    margin: 0 auto;
    border-radius: 25px;
    background: var(--faint-white, rgba(255, 255, 255, 0.4));

    padding: 64px 52px;

    @media (max-width: 768px) {
      padding: 64px 30px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 11px;

    @media (max-width: 1024px) {
      align-self: flex-end;
    }

    @media (max-width: 520px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__count {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
  }

  &__users {
    display: flex;
    flex-direction: column;
    gap: 19px;

    margin-top: 67px;
  }

  .user {
    padding: 24px 16px;
    border-radius: 6px;
    background: #fff;

    display: flex;
    gap: 40px;
    align-items: center;
    color: var(--alt-darker);
    flex-wrap: wrap;

    @media (max-width: 768px) {
      gap: 16px;
    }

    svg {
      color: var(--alt, #118db3);
      min-width: 17px;
    }

    &--invite {
      color: var(--strong-darker);
      svg {
        color: var(--strong, #5ec58f);
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1;

      @media (max-width: 768px) {
        gap: 4px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__name {
      color: var(--darkest, #002060);
      font-size: 20px;
      font-weight: 700;
      line-height: 120%;
      text-align: baseline;

      span {
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        margin-left: 10px;
      }
    }

    &__contact {
      color: var(--alt-darker, #118db3);
      font-size: 17px;
      font-weight: 400;
      line-height: 120%;

      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__badge {
      font-size: 10px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 1.2px;

      padding: 2px 8px;
      border-radius: 4px;
      outline: none;
      border: none;

      &--admin {
        background: rgba(24, 168, 213, 0.15);
        color: var(--alt-darker, #118db3);
      }

      &--invited {
        cursor: pointer;
        color: var(--strong-darker, #3fa06e);
        background: rgba(94, 197, 143, 0.15);
      }
    }
  }
}
