.settings {
  background: #ededed;
  min-height: 100vh;
  padding: 110px 30px;

  @media (max-width: 768px) {
    padding: 64px 0px;
  }

  &__wrapper {
    max-width: 758px;
    margin: 0 auto;
    border-radius: 25px;
    background: var(--faint-white, rgba(255, 255, 255, 0.4));

    padding: 38px 52px;
    margin-bottom: 27px;

    @media (max-width: 768px) {
      padding: 30px;
    }
  }

  &__title {
    color: var(--fribl-blue, #1e4ca9);
    font-size: 21px;
    font-weight: 700;
    line-height: 135%;
    margin-bottom: 30px;
  }

  .details-form {
    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      margin-top: 30px;
      gap: 14px;
    }
  }
}
