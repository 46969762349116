.score {
  background-color: var(--alt);
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate_container {
  width: 100%;
  max-width: 706px;
  margin: 0 auto;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #eee;
    /* gives a clear separation between rows */
    padding: 10px 0;
    background-color: white;
    padding: 1rem;
  }

  .name {
    flex: 1;
    /* This will make sure name takes up all available space */
    margin-right: 20px;
  }



  .sectionName {
    background-color: white;
    width: 8rem;
    flex-shrink: 0;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    text-align: center;
    color: var(--alt);
    align-self: stretch;

    @media (max-width: 520px) {
      width: 100%;
    }
  }

  .sectionName .bigScore {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
  }

  .sectionName p {
    text-transform: capitalize;
  }

  .row {
    display: flex;
    /* set the row to be a flex container */
    /* align-items: center;  */
  }

  .score {
    margin-right: 1rem;
    /* add some spacing to the right of the score */
  }

  .nameEvidenceContainer {
    display: flex;
    /* set it as a flex container */
    flex-direction: column;
    /* stack children vertically */
    flex-grow: 1;
    /* it will take up the remaining space in the row */
  }

  .evidence {
    height: auto;
    max-height: 0rem;
    overflow: hidden;
    margin-top: 5px;
    transition-property: max-height;
    transition-duration: 0.5s;
    transition-delay: 0s;
  }

  .row:hover .evidence {
    max-height: 15rem;
  }

  /* ===================== */

  .box {
    border-radius: 0.5rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    gap: 2px;

    @media (max-width: 520px) {
      flex-direction: column;
    }
  }


  h3 {
    color: var(--alt);
    text-transform: capitalize;
    width: 100%;
    background-color: white;
    padding: 2rem 2rem 2rem 5rem;
  }

  .info-block {
    background-color: var(--darkest);
    border-radius: 0.5rem;
    padding: 2rem 2rem 2rem 8rem;
    gap: 3rem;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    @media (max-width: 1350px) {
      padding: 2rem;
    }

    @media (max-width: 768px) {
      gap: 2rem;
    }
  }

  .contact-details {
    color: white;
    gap: 0.5rem;
    margin-top: 2px;
    width: 13rem;
  }

  .contact-details p {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 13rem;
  }

  .contact-details h4 {
    color: var(--alt);
    padding-right: 0.5em;
  }

  .summary {
    color: white;
    margin-top: 2px;
  }

  em {
    color: var(--alt);
    font-style: normal;
    font-weight: bold;
    font-size: 0.8em;
  }

  .nameEvidenceContainer .weak {
    /* text-decoration: line-through; */
    color: lightgrey;
  }

  .weak em {
    padding-left: 0.5em;
  }

  .h3 span {
    margin-left: 2rem;
  }

  .match-score {
    color: var(--strong);
    padding-left: 1rem;
    font-weight: normal;
    margin-left: 1rem;
  }

  .cat {
    background-color: var(--alt);
    padding: 1rem 0.5rem 1rem 1rem;
    width: 5rem;
  }

  .catName {
    font-size: 0.7rem;
    width: 4rem;
    height: 2rem;
  }

  .pieOuter {
    position: relative;
    width: 4.25rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: conic-gradient(var(--alt) 96deg, var(--lightest) 0deg);

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: white;

    @media (max-width: 520px) {
      width: 2rem;
    }

    p {
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
      background: var(--white);
      color: var(--darkest);
      border-radius: 10000px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .skillType {
    color: var(--darkest);
  }
}

.candidate-header {
  margin-bottom: 2rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  p {
    font-size: 17px;
    font-weight: 700;
    line-height: 120%;
  }

  p:first-child {
    color: var(--darkest);
  }

  &__title {
    flex: 1;
    color: var(--alt-darker, #118db3);
  }

  a {
    color: var(--alt-darker, #118db3);
    font-size: 13px;
    font-weight: 400;
    line-height: 120%;
    text-decoration: none;
  }
}